/* eslint-disable quote-props */
import React from 'react'
const OptionData = {
    yes_no_options: [
        { text: 'Yes', value: '1' },
        { text: 'No', value: '0' }
    ],
    gst_rate_options: [
        { text: '0%', value: '0' },
        { text: '0.01%', value: '0.01' },
        { text: '0.25%', value: '0.25' },
        { text: '1.5%', value: '1.5' },
        { text: '3%', value: '3' },
        { text: '5%', value: '5' },
        { text: '12%', value: '12' },
        { text: '18%', value: '18' },
        { text: '28%', value: '28' },
        { text: 'Exempted', value: 'Exempted' },
        { text: 'Non GST', value: 'Non GST' }
    ],
    textFilterCondition: [
        { text: 'Contains', value: '1' },
        { text: 'Not contains', value: '2' },
        { text: 'Equals', value: '3' },
        { text: 'Not equal', value: '4' },
        { text: 'Start with', value: '9' },
        { text: 'Ends with', value: '10' },
        { text: 'Is empty', value: '12' }
    ],
    numberFilterCondition: [
        { text: 'Equals', value: '3' },
        { text: 'Not equal', value: '4' },
        { text: 'Less than', value: '5' },
        { text: 'Less than or equals', value: '6' },
        { text: 'Greater than', value: '7' },
        { text: 'Greater than or equals', value: '8' },
        { text: 'In range', value: '11' },
        { text: 'Is empty', value: '12' }
    ],
    dateFilterCondition: [
        { text: 'Equals', value: '3' },
        { text: 'Not equal', value: '4' },
        { text: 'Less than', value: '5' },
        { text: 'Less than or equals', value: '6' },
        { text: 'Greater than', value: '7' },
        { text: 'Greater than or equals', value: '8' },
        { text: 'In range', value: '11' },
        { text: 'Is empty', value: '12' }
    ],
    selectFilterCondition: [
        { text: 'Contains', value: '1' },
        { text: 'Not contains', value: '2' },
        { text: 'Equals', value: '3' },
        { text: 'Not equal', value: '4' },
        { text: 'Is empty', value: '12' }
    ],
    statusFilterCondition: [
        { text: 'Contains', value: '1' },
        { text: 'Not contains', value: '2' },
        { text: 'Equals', value: '3' },
        { text: 'Not equal', value: '4' }
    ],
    module_activity_option: [
        { text: 'All', value: '' },
        { text: 'Company', value: '1' }
    ],
    activity_operation_option: [
        { text: 'All', value: '' },
        { text: 'Create', value: '1' },
        { text: 'Update', value: '2' },
        { text: 'Delete', value: '3' },
        { text: 'Active', value: '15' },
        { text: 'Deactive', value: '16' },
        { text: 'Import', value: '10' }
    ],
    country_options: [{ label: 'India', value: 101 }],
    company_type_options: [
        { text: 'Regular', value: 'Regular' },
        { text: 'Composition', value: 'Composition' }
    ],
    GST_return_history_status: [
        { text: 'Filed', value: '2' },
        { text: 'Pending', value: '3' }
    ],
    GST_return_history_list_status: [
        { text: 'Success', value: 'SUCCESS' },
        { text: 'Pending', value: 'PENDING' }
    ],
    filing_type_options: [
        { text: 'GSTR-1', value: 'GSTR-1' },
        { text: 'GSTR-1 IFF', value: 'GSTR-1 IFF' },
        { text: 'GSTR-3B', value: 'GSTR-3B' },
        { text: 'GSTR-3B/NIL', value: 'GSTR-3B/NIL' },
        { text: 'CMP-08', value: 'CMP-08' },
        { text: 'CMP-08/NIL', value: 'CMP-08/NIL' }
    ],
    export_type_option: [
        { text: 'WPAY', value: 'WPAY' },
        { text: 'WOPAY', value: 'WOPAY' }
    ],
    rate_options: [
        { text: '0.00', value: '0.00' },
        { text: '0.10', value: '0.10' },
        { text: '0.25', value: '0.25' },
        { text: '1.00', value: '1.00' },
        { text: '1.50', value: '1.50' },
        { text: '3.00', value: '3.00' },
        { text: '5.00', value: '5.00' },
        { text: '6.00', value: '6.00' },
        { text: '7.50', value: '7.50' },
        { text: '12.00', value: '12.00' },
        { text: '18.00', value: '18.00' },
        { text: '28.00', value: '28.00' }
    ],
    isconcesstional: [
        { text: 'YES', value: '1' },
        { text: 'NO', value: '0' }
    ],
    sgst_rate_options: [
        { text: '0.00', value: '0.00' },
        { text: '0.05', value: '0.05' },
        { text: '0.125', value: '0.125' },
        { text: '0.50', value: '0.50' },
        { text: '0.75', value: '0.75' },
        { text: '1.50', value: '1.50' },
        { text: '2.50', value: '2.50' },
        { text: '3.00', value: '3.00' },
        { text: '3.75', value: '3.75' },
        { text: '6.00', value: '6.00' },
        { text: '9.00', value: '9.00' },
        { text: '14.00', value: '14.00' }
    ],
    supply_type_options: [
        { text: 'Regular B2B', value: 'R' },
        { text: 'SEZ supplies with payment', value: 'SEWP' },
        { text: 'SEZ supplies without payment', value: 'SEWOP' },
        { text: 'Deemed Exp', value: 'DE' },
        { text: 'Intra-State supplies attracting IGST', value: 'CBW' }
    ],
    seaction_15_supply_type_options: [
        { text: 'Regular B2B', value: 'R' },
        { text: 'Deemed Exp', value: 'DE' },
        { text: 'SEZ supplies with payment', value: 'SEWP' },
        { text: 'SEZ supplies without payment', value: 'SEWOP' }
    ],
    b2cs_type_options: [
        { text: 'OE', value: 'OE' },
        { text: 'E', value: 'E', disabled: true }
    ],
    exemp_description_option: [
        { text: 'Inter-State supplies to registered persons', value: 'INTRB2B' },
        { text: 'Intra-State supplies to registered persons', value: 'INTRAB2B' },
        { text: 'Inter-State supplies to unregistered persons', value: 'INTRB2C' },
        { text: 'Intra-State supplies to unregistered persons', value: 'INTRAB2C' }
    ],
    place_of_supply_options: [
        { value: '01-Jammu & Kashmir', text: '01-Jammu & Kashmir' },
        { value: '02-Himachal Pradesh', text: '02-Himachal Pradesh' },
        { value: '03-Punjab', text: '03-Punjab' },
        { value: '04-Chandigarh', text: '04-Chandigarh' },
        { value: '05-Uttarakhand', text: '05-Uttarakhand' },
        { value: '06-Haryana', text: '06-Haryana' },
        { value: '07-Delhi', text: '07-Delhi' },
        { value: '08-Rajasthan', text: '08-Rajasthan' },
        { value: '09-Uttar Pradesh', text: '09-Uttar Pradesh' },
        { value: '10-Bihar', text: '10-Bihar' },
        { value: '11-Sikkim', text: '11-Sikkim' },
        { value: '12-Arunachal Pradesh', text: '12-Arunachal Pradesh' },
        { value: '13-Nagaland', text: '13-Nagaland' },
        { value: '14-Manipur', text: '14-Manipur' },
        { value: '15-Mizoram', text: '15-Mizoram' },
        { value: '16-Tripura', text: '16-Tripura' },
        { value: '17-Meghalaya', text: '17-Meghalaya' },
        { value: '18-Assam', text: '18-Assam' },
        { value: '19-West Bengal', text: '19-West Bengal' },
        { value: '20-Jharkhand', text: '20-Jharkhand' },
        { value: '21-Odisha', text: '21-Odisha' },
        { value: '22-Chhattisgarh', text: '22-Chhattisgarh' },
        { value: '23-Madhya Pradesh', text: '23-Madhya Pradesh' },
        { value: '24-Gujarat', text: '24-Gujarat' },
        { value: '25-Daman & Diu', text: '25-Daman & Diu' },
        { value: '26-Dadra & Nagar Haveli & Daman & Diu', text: '26-Dadra & Nagar Haveli & Daman & Diu' },
        { value: '27-Maharashtra', text: '27-Maharashtra' },
        { value: '29-Karnataka', text: '29-Karnataka' },
        { value: '30-Goa', text: '30-Goa' },
        { value: '31-Lakshdweep', text: '31-Lakshdweep' },
        { value: '32-Kerala', text: '32-Kerala' },
        { value: '33-Tamil Nadu', text: '33-Tamil Nadu' },
        { value: '34-Puducherry', text: '34-Puducherry' },
        { value: '35-Andaman & Nicobar Islands', text: '35-Andaman & Nicobar Islands' },
        { value: '36-Telangana', text: '36-Telangana' },
        { value: '37-Andhra Pradesh', text: '37-Andhra Pradesh' },
        { value: '38-Ladakh', text: '38-Ladakh' },
        { value: '97-Other Territory', text: '97-Other Territory' }
    ],
    place_of_supply_gstr3b: { '01': '01-Jammu & Kashmir', '02': '02-Himachal Pradesh', '03': '03-Punjab', '04': '04-Chandigarh', '05': '05-Uttarakhand', '06': '06-Haryana', '07': '07-Delhi', '08': '08-Rajasthan', '09': '09-Uttar Pradesh', '10': '10-Bihar', '11': '11-Sikkim', '12': '12-Arunachal Pradesh', '13': '13-Nagaland', '14': '14-Manipur', '15': '15-Mizoram', '16': '16-Tripura', '17': '17-Meghalaya', '18': '18-Assam', '19': '19-West Bengal', '20': '20-Jharkhand', '21': '21-Odisha', '22': '22-Chhattisgarh', '23': '23-Madhya Pradesh', '24': '24-Gujarat', '25': '25-Daman & Diu', '26': '26-Dadra & Nagar Haveli & Daman & Diu', '27': '27-Maharashtra', '29': '29-Karnataka', '30': '30-Goa', '31': '31-Lakshdweep', '32': '32-Kerala', '33': '33-Tamil Nadu', '34': '34-Puducherry', '35': '35-Andaman & Nicobar Islands', '36': '36-Telangana', '37': '37-Andhra Pradesh', '38': '38-Ladakh', '97': '97-Other Territory' },
    applicable_of_tax_options: [
        { text: 'None', value: '0.00' },
        { text: '65.00', value: '65.00' }
    ],
    note_type_options: [
        { text: 'Debit Note', value: 'D' },
        { text: 'Credit Note', value: 'C' }
    ],
    diff_tax_rate_leased_cars: [{ text: 'None', value: '0.00' }],
    ur_type_options: [
        { text: 'B2CL', value: 'B2CL' },
        { text: 'EXPWP', value: 'EXPWP' },
        { text: 'EXPWOP', value: 'EXPWOP' }
    ],
    gstr1_option_data: [
        { text: 'B2B', value: '1' },
        { text: 'B2CS', value: '2' },
        { text: 'B2C LARGE', value: '3' },
        { text: 'EXPORT', value: '4' },
        { text: 'CDNR', value: '5' },
        { text: 'CDNUR', value: '6' },
        { text: 'Advanced Received', value: '7' },
        { text: 'HSN', value: '8' },
        { text: 'Docs', value: '9' }
    ],
    iff_option_data: [
        { text: 'B2B', value: '1' },
        { text: 'CDNR', value: '2' }
    ],
    gstr2_option_data: [
        { text: 'B2B', value: '1' },
        { text: 'B2BUR', value: '2' },
        { text: 'IMPS', value: '3' },
        { text: 'IMPG', value: '4' },
        { text: 'CDNR', value: '5' },
        { text: 'CDNUR', value: '6' },
        { text: 'AT', value: '7' },
        { text: 'ATADJ', value: '8' },
        { text: 'EXEMP', value: '9' },
        { text: 'ITCR', value: '10' },
        { text: 'HSN', value: '11' }
    ],
    gstr1_amedment_option_data: [
        { text: 'B2B Amendment', value: '1' },
        { text: 'B2CS Amendment', value: '2' },
        { text: 'B2C LARGE Amendment', value: '3' },
        { text: 'EXPORT Amendment', value: '4' },
        { text: 'CDNR Amendment', value: '5' },
        { text: 'CDNUR Amendment', value: '6' },
        { text: 'Advanced Received Amendment', value: '7' },
        { text: 'HSN', value: '8' },
        { text: 'Docs', value: '9' }
    ],
    iff_amedment_option_data: [
        { text: 'B2B Amendment', value: '1' },
        { text: 'CDNR Amendment', value: '2' }
    ],
    gstr1_unit_of_measurement_option_data: [
        { text: 'BAG-BAGS', value: 'BAG-BAGS' },
        { text: 'BAL-BALE', value: 'BAL-BALE' },
        { text: 'BDL-BUNDLES', value: 'BDL-BUNDLES' },
        { text: 'BKL-BUCKLES', value: 'BKL-BUCKLES' },
        { text: 'BOU-BILLION OF UNITS', value: 'BOU-BILLION OF UNITS' },
        { text: 'BOX-BOX', value: 'BOX-BOX' },
        { text: 'BTL-BOTTLES', value: 'BTL-BOTTLES' },
        { text: 'BUN-BUNCHES', value: 'BUN-BUNCHES' },
        { text: 'CAN-CANS', value: 'CAN-CANS' },
        { text: 'CBM-CUBIC METERS', value: 'CBM-CUBIC METERS' },
        { text: 'CCM-CUBIC CENTIMETERS', value: 'CCM-CUBIC CENTIMETERS' },
        { text: 'CMS-CENTIMETERS', value: 'CMS-CENTIMETERS' },
        { text: 'CTN-CARTONS', value: 'CTN-CARTONS' },
        { text: 'DOZ-DOZENS', value: 'DOZ-DOZENS' },
        { text: 'DRM-DRUMS', value: 'DRM-DRUMS' },
        { text: 'GGK-GREAT GROSS', value: 'GGK-GREAT GROSS' },
        { text: 'GMS-GRAMMES', value: 'GMS-GRAMMES' },
        { text: 'GRS-GROSS', value: 'GRS-GROSS' },
        { text: 'GYD-GROSS YARDS', value: 'GYD-GROSS YARDS' },
        { text: 'KGS-KILOGRAMS', value: 'KGS-KILOGRAMS' },
        { text: 'KLR-KILOLITRE', value: 'KLR-KILOLITRE' },
        { text: 'KME-KILOMETRE', value: 'KME-KILOMETRE' },
        { text: 'LTR-LITRES', value: 'LTR-LITRES' },
        { text: 'MLT-MILILITRE', value: 'MLT-MILILITRE' },
        { text: 'MTR-METERS', value: 'MTR-METERS' },
        { text: 'MTS-METRIC TON', value: 'MTS-METRIC TON' },
        { text: 'NOS-NUMBERS', value: 'NOS-NUMBERS' },
        { text: 'PAC-PACKS', value: 'PAC-PACKS' },
        { text: 'PCS-PIECES', value: 'PCS-PIECES' },
        { text: 'PRS-PAIRS', value: 'PRS-PAIRS' },
        { text: 'QTL-QUINTAL', value: 'QTL-QUINTAL' },
        { text: 'ROL-ROLLS', value: 'ROL-ROLLS' },
        { text: 'SET-SETS', value: 'SET-SETS' },
        { text: 'SQF-SQUARE FEET', value: 'SQF-SQUARE FEET' },
        { text: 'SQM-SQUARE METERS', value: 'SQM-SQUARE METERS' },
        { text: 'SQY-SQUARE YARDS', value: 'SQY-SQUARE YARDS' },
        { text: 'TBS-TABLETS', value: 'TBS-TABLETS' },
        { text: 'TGM-TEN GROSS', value: 'TGM-TEN GROSS' },
        { text: 'THD-THOUSANDS', value: 'THD-THOUSANDS' },
        { text: 'TON-TONNES', value: 'TON-TONNES' },
        { text: 'TUB-TUBES', value: 'TUB-TUBES' },
        { text: 'UGS-US GALLONS', value: 'UGS-US GALLONS' },
        { text: 'UNT-UNITS', value: 'UNT-UNITS' },
        { text: 'YDS-YARDS', value: 'YDS-YARDS' },
        { text: 'OTH-OTHERS', value: 'OTH-OTHERS' },
        { text: 'NA', value: 'NA' }
    ],
    y_n_option: [
        { text: 'Y', value: 'Y', label: '1' },
        { text: 'N', value: 'N', label: '0' }
    ],
    nature_of_document_option_data: [
        { text: 'Invoices for outward supply', value: 'Invoices for outward supply' },
        { text: 'Invoices for inward supply from unregistered person', value: 'Invoices for inward supply from unregistered person' },
        { text: 'Revised Invoice', value: 'Revised Invoice' },
        { text: 'Debit Note', value: 'Debit Note' },
        { text: 'Credit Note', value: 'Credit Note' },
        { text: 'Receipt Voucher', value: 'Receipt Voucher' },
        { text: 'Payment Voucher', value: 'Payment Voucher' },
        { text: 'Refund Voucher', value: 'Refund Voucher' },
        { text: 'Delivery Challan for job work', value: 'Delivery Challan for job work' }
    ],
    original_month_option_data: [
        { text: 'JANUARY', value: 'JANUARY', label: 'Jan' },
        { text: 'FEBRUARY', value: 'FEBRUARY', label: 'Feb' },
        { text: 'MARCH', value: 'MARCH', label: 'Mar' },
        { text: 'APRIL', value: 'APRIL', label: 'Apr' },
        { text: 'MAY', value: 'MAY', label: 'May' },
        { text: 'JUNE', value: 'JUNE', label: 'Jun' },
        { text: 'JULY', value: 'JULY', label: 'Jul' },
        { text: 'AUGUST', value: 'AUGUST', label: 'Aug' },
        { text: 'SEPTEMBER', value: 'SEPTEMBER', label: 'Sep' },
        { text: 'OCTOBER', value: 'OCTOBER', label: 'Oct' },
        { text: 'NOVEMBER', value: 'NOVEMBER', label: 'Nov' },
        { text: 'DECEMBER', value: 'DECEMBER', label: 'Dec' }
    ],
    item_type_options: [
        { text: 'PRODUCT', value: 'P' },
        { text: 'SERVICE', value: 'S' }
    ],
    nil_exemp_nongst_option: [
        { text: 'Nil rated', value: 'Nil rated', label: '0' },
        { text: 'Exempt', value: 'Exempt', label: '1' },
        { text: 'NonGST', value: 'NonGST', label: '2' }
    ],
    cdn_reason_options: [
        { text: 'Sales Return', value: 'Sales Return', label: '0' },
        { text: 'Post sale discount', value: 'Post sale discount', label: '1' },
        { text: 'Deficiency in service', value: 'Deficiency in service', label: '2' },
        { text: 'Correction in invoice', value: 'Correction in invoice', label: '3' },
        { text: 'Change in POS', value: 'Change in POS', label: '4' },
        { text: 'Finalization of Provisional assessment', value: 'Finalization of Provisional assessment', label: '5' },
        { text: 'Others', value: 'Others', label: '6' }
    ],
    invoice_type_with_note_options: [
        { text: 'Regular B2B', value: 'Regular B2B', label: 'R' },
        { text: 'Export with Payment of GST', value: 'Export with Payment of GST', label: 'WPAY', label1: 'EXPWP' },
        { text: 'Export without Payment of GST', value: 'Export without Payment of GST', label: 'WOPAY', label1: 'EXPWOP' },
        { text: 'SEZ supplies with payment', value: 'SEZ supplies with payment', label: 'SEWP' },
        { text: 'SEZ supplies without payment', value: 'SEZ supplies without payment', label: 'SEWOP' },
        { text: 'Deemed Exp', value: 'Deemed Exp', label: 'DE' },
        { text: 'Intra-State supplies attracting IGST', value: 'Intra-State supplies attracting IGST', label: 'CBW' }
    ],
    customer_type_options: [
        { text: 'Composition', value: 'Composition', label: '0' },
        { text: 'UIN', value: 'UIN', label: '1' }
    ],
    static_months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    static_quarter: ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'],
    status_option_data: [
        { text: 'Pending', value: '3' },
        { text: 'Filed', value: '2' }
    ],
    frequency_options: [
        { text: 'Monthly', value: '0' },
        { text: 'Quarterly', value: '1' }
    ],
    iff_check_month: ['Mar', 'Jun', 'Sep', 'Dec'],
    pagination_option_10_50: [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' }
    ],
    static_quarter_month: {
        Q1: ['Apr', 'May', 'Jun'],
        Q2: ['Jul', 'Aug', 'Sep'],
        Q3: ['Oct', 'Nov', 'Dec'],
        Q4: ['Jan', 'Feb', 'Mar']
    },
    itc_type: {
        itc_avail_a1: 'IPMG',
        itc_avail_a2: 'IMPS',
        itc_avail_a3: 'ISRC',
        itc_avail_a4: 'ISD',
        itc_avail_a5: 'OTH',
        itc_rev_b1: 'RUL',
        itc_rev_b2: 'OTH',
        itc_inelg_d1: 'RUL',
        itc_inelg_d2: 'OTH'
    },
    otc_options: [
        { value: "UTIB", text: "AXIS BANK" },
        { value: "BARB", text: "BANK OF BARODA" },
        { value: "BKID", text: "BANK OF INDIA" },
        { value: "MAHB", text: "BANK OF MAHARASHTRA" },
        { value: "CNRB", text: "CANARA BANK" },
        { value: "CBIN", text: "CENTRAL BANK OF INDIA" },
        { value: "CIUB", text: "CITY UNION BANK LIMITED" },
        { value: "DCBL", text: "DCB BANK LIMITED" },
        { value: "DLXB", text: "DHANLAXMI BANK LIMITED" },
        { value: "FDRL", text: "FEDERAL BANK" },
        { value: "HDFC", text: "HDFC BANK" },
        { value: "ICIC", text: "ICICI BANK LIMITED" },
        { value: "IBKL", text: "IDBI BANK" },
        { value: "IDIB", text: "INDIAN BANK" },
        { value: "IOBA", text: "INDIAN OVERSEAS BANK" },
        { value: "INDB", text: "INDUSIND BANK" },
        { value: "JAKA", text: "JAMMU AND KASHMIR BANK LIMITED" },
        { value: "KARB", text: "KARNATAKA BANK LIMITED" },
        { value: "KVBL", text: "KARUR VYSYA BANK" },
        { value: "KKBK", text: "KOTAK MAHINDRA BANK LIMITED" },
        { value: "PSIB", text: "PUNJAB AND SIND BANK" },
        { value: "PUNB", text: "PUNJAB NATIONAL BANK" },
        { value: "RATN", text: "RBL Bank Limited" },
        { value: "SIBL", text: "SOUTH INDIAN BANK" },
        { value: "SBIN", text: "STATE BANK OF INDIA" },
        { value: "UCBA", text: "UCO BANK" },
        { value: "UBIN", text: "UNION BANK OF INDIA" }
    ],
    cc_options: [
        { value: "UTIB", text: "AXIS BANK" },
        { value: "HDFC", text: "HDFC BANK" },
        { value: "KKBK", text: "KOTAK MAHINDRA BANK LIMITED" }
    ],
    upi_options: [
        { value: "UTIB", text: "AXIS BANK" },
        { value: "HDFC", text: "HDFC BANK" },
        { value: "KARB", text: "KARNATAKA BANK LIMITED" },
        { value: "KKBK", text: "KOTAK MAHINDRA BANK LIMITED" }
    ],
    neft_rtgs_options: [
        { value: "ABHY", text: "ABHYUDAYA COOPERATIVE BANK LIMITED" },
        { value: "ADCB", text: "ABU DHABI COMMERCIAL BANK" },
        { value: "ABPB", text: "ADITYA BIRLA IDEA PAYMENTS BANK" },
        { value: "AMCB", text: "AHMEDABAD MERCANTILE COOPERATIVE BANK" },
        { value: "AMDN", text: "AHMEDNAGAR MERCHANTS CO-OP. BANK LTD." },
        { value: "AIRP", text: "AIRTEL PAYMENTS BANK LIMITED" },
        { value: "AKJB", text: "AKOLA JANATA COMMERCIAL COOPERATIVE BANK LTD." },
        { value: "ALLA", text: "ALLAHABAD BANK" },
        { value: "AUCB", text: "ALMORA URBAN COOPERATIVE BANK LIMITED" },
        { value: "AJHC", text: "AMBARNATH JAIHIND COOP BANK LTD AMBARNATH" },
        { value: "ANDB", text: "ANDHRA BANK" },
        { value: "APGV", text: "ANDHRA PRADESH GRAMEENA VIKAS BANK" },
        { value: "APGB", text: "ANDHRA PRAGATHI GRAMEENA BANK" },
        { value: "ASBL", text: "APNA SAHAKARI BANK LTD." },
        { value: "ARBL", text: "ARVIND SAHAKARI BANK LTD" },
        { value: "AUBL", text: "AU SMALL FINANCE BANK LIMITED" },
        { value: "ANZB", text: "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED" },
        { value: "UTIB", text: "AXIS BANK" },
        { value: "BNPA", text: "B N P PARIBAS" },
        { value: "BDBL", text: "BANDHAN BANK LIMITED" },
        { value: "IBBK", text: "BANK INTERNATIONAL INDONESIA" },
        { value: "BOFA", text: "BANK OF AMERICA" },
        { value: "BBKM", text: "BANK OF BAHARAIN AND KUWAIT BSC" },
        { value: "BARB", text: "BANK OF BARODA" },
        { value: "BCEY", text: "BANK OF CEYLON" },
        { value: "BKID", text: "BANK OF INDIA" },
        { value: "MAHB", text: "BANK OF MAHARASHTRA" },
        { value: "BARA", text: "BARAMATI SAHAKARI BANK LIMITED" },
        { value: "BARC", text: "BARCLAYS BANK" },
        { value: "BACB", text: "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED" },
        { value: "BNSB", text: "BHAGINI NIVEDITA SAHAKARI BANK LTD. PUNE" },
        { value: "BCBM", text: "BHARAT COOPERATIVE BANK MUMBAI LIMITED" },
        { value: "BMBL", text: "BHARATIYA MAHILA BANK LIMITED" },
        { value: "BMCB", text: "BOMBAY MERCANTILE COOPERATIVE BANK LTD" },
        { value: "CNRB", text: "CANARA BANK" },
        { value: "CLBL", text: "CAPITAL SMALL FINANCE BANK LIMITED" },
        { value: "CSBK", text: "CATHOLIC SYRIAN BANK LIMITED" },
        { value: "CBIN", text: "CENTRAL BANK OF INDIA" },
        { value: "CRGB", text: "CHHATTISGARH RAJYA GRAMIN BANK" },
        { value: "CTCB", text: "CHINATRUST COMMERCIAL BANK LIMITED" },
        { value: "CITI", text: "CITI BANK N A" },
        { value: "CCBL", text: "CITIZEN CREDIT COOPERATIVE BANK LIMITED" },
        { value: "CIUB", text: "CITY UNION BANK LIMITED" },
        { value: "COAS", text: "COASTAL LOCAL AREA BANK LTD" },
        { value: "CTBA", text: "COMMONWEALTH BANK OF AUSTRALIA" },
        { value: "CORP", text: "CORPORATION BANK" },
        { value: "CRLY", text: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK" },
        { value: "CRES", text: "CREDIT SUISSE AG" },
        { value: "DCUB", text: "Darussalam Co operative Urban Bank Ltd" },
        { value: "DBSS", text: "DBS BANK" },
        { value: "DCBB", text: "DCB BANK LIMITED" },
        { value: "BKDN", text: "DENA BANK" },
        { value: "DEOB", text: "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD" },
        { value: "DICG", text: "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION" },
        { value: "DEUT", text: "DEUTSCHE BANK" },
        { value: "DBOS", text: "DEVELOPMENT BANK OF SINGAPORE" },
        { value: "DCBL", text: "DEVELOPMENT CREDIT BANK LIMITED" },
        { value: "DLXB", text: "DHANALAKSHMI BANK" },
        { value: "DMKJ", text: "DMK JAOLI BANK" },
        { value: "DOHB", text: "DOHA BANK" },
        { value: "DOHA", text: "DOHA BANK QSC" },
        { value: "DNSB", text: "DOMBIVLI NAGARI SAHAKARI BANK LIMITED" },
        { value: "DURG", text: "DURGAPUR STEEL PEOPLES CO-OPERATIVE BANK LTD" },
        { value: "EBIL", text: "EMIRATES NBD BANK P J S C" },
        { value: "ESFB", text: "EQUITAS SMALL FINANCE BANK LIMITED" },
        { value: "ESMF", text: "ESAF SMALL FINANCE BANK LIMITED" },
        { value: "EIBI", text: "EXPORT IMPORT BANK OF INDIA" },
        { value: "EXIB", text: "EXPORT IMPORT BANK OF INDIA" },
        { value: "FDRL", text: "FEDERAL BANK" },
        { value: "FSFB", text: "FINCARE SMALL FINANCE BANK LTD" },
        { value: "FINO", text: "FINO PAYMENTS BANK LIMITED" },
        { value: "FIRN", text: "FIRSTRAND BANK LIMITED" },
        { value: "PJSB", text: "G P PARSIK BANK" },
        { value: "GGBK", text: "GURGAON GRAMIN BANK" },
        { value: "HARC", text: "HARYANA STATE COOPERATIVE BANK" },
        { value: "HDFC", text: "HDFC BANK" },
        { value: "HPSC", text: "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD" },
        { value: "HSBC", text: "HSBC BANK" },
        { value: "HUSB", text: "Hutatma Sahakari Bank Ltd" },
        { value: "ICIC", text: "ICICI BANK LIMITED" },
        { value: "IBKL", text: "IDBI BANK" },
        { value: "IDFB", text: "IDFC FIRST BANK LIMITED" },
        { value: "IDUK", text: "IDUKKI DISTRICT CO OPERATIVE BANK LTD" },
        { value: "IPOS", text: "INDIA POST PAYMENTS BANK LIMITED" },
        { value: "IDIB", text: "INDIAN BANK" },
        { value: "IOBA", text: "INDIAN OVERSEAS BANK" },
        { value: "INDB", text: "INDUSIND BANK" },
        { value: "ICBK", text: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED" },
        { value: "IBKO", text: "INDUSTRIAL BANK OF KOREA" },
        { value: "VYSA", text: "ING VYSYA BANK" },
        { value: "ITBL", text: "IRINJALAKUDA TOWN CO-OPERATIVE BANK LTD." },
        { value: "JJSB", text: "JALGAON JANATA SAHAKARI BANK LIMITED" },
        { value: "JAKA", text: "JAMMU AND KASHMIR BANK LIMITED" },
        { value: "JSFB", text: "JANA SMALL FINANCE BANK LTD." },
        { value: "JSBL", text: "JANAKALYAN SAHAKARI BANK LIMITED" },
        { value: "JASB", text: "JANASEVA SAHAKARI BANK BORIVLI LIMITED" },
        { value: "JANA", text: "JANASEVA SAHAKARI BANK LIMITED" },
        { value: "JSBP", text: "JANATA SAHAKARI BANK LIMITED" },
        { value: "JTSC", text: "JANATHA SEVA COOPERATIVE BANK LTD" },
        { value: "JIOP", text: "JIO PAYMENTS BANK LIMITED" },
        { value: "CHAS", text: "JP MORGAN BANK" },
        { value: "KAIJ", text: "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED" },
        { value: "KCCB", text: "KALUPUR COMMERCIAL CO-OP BANK" },
        { value: "KJSB", text: "KALYAN JANATA SAHAKARI BANK" },
        { value: "KCBL", text: "KAPOL COOPERATIVE BANK LIMITED" },
        { value: "KARB", text: "KARNATAKA BANK LIMITED" },
        { value: "KVGB", text: "KARNATAKA VIKAS GRAMEENA BANK" },
        { value: "KVBL", text: "KARUR VYSYA BANK" },
        { value: "KGRB", text: "KAVERI GRAMEENA BANK" },
        { value: "KOEX", text: "KEB HANA BANK" },
        { value: "KLGB", text: "KERALA GRAMIN BANK" },
        { value: "KBKB", text: "KOOKMIN BANK" },
        { value: "KKBK", text: "KOTAK MAHINDRA BANK LIMITED" },
        { value: "KDCB", text: "KOZHIKODE DISTRICT CO-OPERATIVE BANK LIMITED" },
        { value: "KRTH", text: "KRUNG THAI BANK PCL" },
        { value: "LAVB", text: "LAXMI VILAS BANK LTD." },
        { value: "MCBL", text: "MAHANAGAR COOPERATIVE BANK" },
        { value: "MAHG", text: "Maharashtra Gramin Bank" },
        { value: "MSCI", text: "MAHARASHTRA STATE COOPERATIVE BANK" },
        { value: "MSBL", text: "Mahesh Sahakari Bank Ltd Pune" },
        { value: "MSHQ", text: "MASHREQBANK PSC" },
        { value: "MHCB", text: "MIZUHO BANK LTD" },
        { value: "MDBK", text: "MODEL COOPERATIVE BANK LTD" },
        { value: "BOTM", text: "MUFG BANK, LTD" },
        { value: "NUCB", text: "NAGAR URBAN CO OPERATIVE BANK" },
        { value: "NGSB", text: "NAGPUR NAGARIK SAHAKARI BANK LIMITED" },
        { value: "NATA", text: "NATIONAL AUSTRALIA BANK LIMITED" },
        { value: "NBRD", text: "NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT" },
        { value: "NBAD", text: "NATIONAL BANK OF ABU DHABI PJSC" },
        { value: "NJBK", text: "NAV JEEVAN CO OP BANK LTD" },
        { value: "NICB", text: "NEW INDIA COOPERATIVE BANK LIMITED" },
        { value: "NKGS", text: "NKGSB COOPERATIVE BANK LIMITED" },
        { value: "NESF", text: "NORTH EAST SMALL FINANCE BANK LIMITED" },
        { value: "NSPB", text: "NSDL PAYMENTS BANK LIMITED" },
        { value: "NNSB", text: "NUTAN NAGARIK SAHAKARI BANK LIMITED" },
        { value: "ORBC", text: "ORIENTAL BANK OF COMMERCE" },
        { value: "ORCB", text: "ORISSA STATE COOPERATIVE BANK BANK LTD" },
        { value: "PSBL", text: "Pavana Sahakari Bank LTD" },
        { value: "PYTM", text: "PAYTM PAYMENTS BANK LIMITED" },
        { value: "PKGB", text: "PRAGATHI KRISHNA GRAMIN BANK" },
        { value: "PRTH", text: "PRATHAMA BANK" },
        { value: "PMEC", text: "PRIME COOPERATIVE BANK LIMITED" },
        { value: "PTBI", text: "PT BANK MAYBANK INDONESIA TBK" },
        { value: "PMCB", text: "PUNJAB AND MAHARSHTRA COOPERATIVE BANK" },
        { value: "PSIB", text: "PUNJAB AND SIND BANK" },
        { value: "PUNB", text: "PUNJAB NATIONAL BANK" },
        { value: "QNBA", text: "QATAR NATIONAL BANK SAQ" },
        { value: "RABO", text: "RABOBANK INTERNATIONAL" },
        { value: "RRBP", text: "RAJARAMBAPU SAHAKARI BANK LIMITED" },
        { value: "RSSB", text: "RAJARSHI SHAHU SAHAKARI BANK LTD  PUNE" },
        { value: "RMGB", text: "RAJASTHAN MARUDHARA GRAMIN BANK" },
        { value: "RSBL", text: "RAJGURUNAGAR SAHAKARI BANK LIMITED" },
        { value: "RNSB", text: "RAJKOT NAGRIK SAHAKARI BANK LIMITED" },
        { value: "RDCB", text: "Rajnandgaon District Central Co-operative Bank Ltd" },
        { value: "RATN", text: "RBL Bank Limited" },
        { value: "RBLB", text: "RBL Bank Limited" },
        { value: "RBIS", text: "RESERVE BANK OF INDIA, PAD" },
        { value: "SAHE", text: "SAHEBRAO DESHMUKH COOPERATIVE BANK LIMITED" },
        { value: "SBLS", text: "SAMARTH SAHAKARI BANK LTD" },
        { value: "SANT", text: "SANT SOPANKAKA SAHAKARI BANK LTD." },
        { value: "SNBK", text: "Saraspur Nagrik Cooperative Bank Ltd Saraspur." },
        { value: "SRCB", text: "SARASWAT CO-OPERATIVE BANK LTD." },
        { value: "SDCE", text: "SATARA DISTRICT CENTRAL CO OP BANK LTD" },
        { value: "TSSB", text: "Satara Sahakari Bank Ltd" },
        { value: "SGBA", text: "Saurashtra Gramin Bank" },
        { value: "SABR", text: "SBER BANK" },
        { value: "SBMB", text: "SBM BANK MAURITIUS LIMITED" },
        { value: "SKSB", text: "SHIKSHAK SAHAKARI BANK LIMITED" },
        { value: "SHBK", text: "SHINHAN BANK" },
        { value: "SMCB", text: "SHIVALIK MERCANTILE CO OPERATIVE BANK LTD" },
        { value: "SKNB", text: "Shree Kadi Nagarik Sahakari Bank Limited" },
        { value: "CRUB", text: "SHRI CHHATRAPATI RAJASHRI SHAHU URBAN COOPERATIVE BANK LIMITED" },
        { value: "SVSH", text: "SHRI VEERSHAIV CO-OP. BANK LTD." },
        { value: "MVCB", text: "SIR M VISVESVARAYA CO-OPERATIVE BANK LTD." },
        { value: "SIDB", text: "SMALL INDUSTRIES DEVELOPMENT BANK OF INDIA" },
        { value: "SMNB", text: "Smriti Nagrik Sahakari Bank Maryadit" },
        { value: "SOGE", text: "SOCIETE GENERALE" },
        { value: "SJSB", text: "SOLAPUR JANATA SAHAKARI BANK LIMITED" },
        { value: "SIBL", text: "SOUTH INDIAN BANK" },
        { value: "SECB", text: "SREE CHARAN SOUHARDHA COOPERATIVE BANK LTD" },
        { value: "SCBL", text: "STANDARD CHARTERED BANK" },
        { value: "SBIN", text: "STATE BANK OF INDIA" },
        { value: "STCB", text: "STATE BANK OF MAURITIUS" },
        { value: "SUSB", text: "Suco Souharda Sahakari Bank Ltd" },
        { value: "SMBC", text: "SUMITOMO MITSUI BANKING CORPORATION" },
        { value: "SUNB", text: "SURAT NATIONAL COOPERATIVE BANK LIMITED" },
        { value: "SURY", text: "SURYODAY SMALL FINANCE BANK LIMITED" },
        { value: "SUTB", text: "SUTEX COOPERATIVE BANK LIMITED" },
        { value: "SYNB", text: "SYNDICATE BANK" },
        { value: "TMBL", text: "TAMILNAD MERCANTILE BANK LTD" },
        { value: "TSAB", text: "TELANGANA STATE COOP APEX BANK" },
        { value: "TTCB", text: "TEXTILE TRADERS CO-OPERATIVE BANK LIMITED" },
        { value: "APMC", text: "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED" },
        { value: "AHDC", text: "THE AHMEDNAGAR DISTRICT CENTRAL CO-OPERATIVE BANK LTD" },
        { value: "AJAR", text: "The Ajara Urban Co op Bank Ltd Ajara" },
        { value: "ADCC", text: "THE AKOLA DISTRICT CENTRAL COOPERATIVE BANK" },
        { value: "TAUB", text: "The Akola Urban Cooperative Bank Limited" },
        { value: "APBL", text: "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED" },
        { value: "TBMC", text: "The Banaskantha Mercantile Cooperative Bank Ltd" },
        { value: "NOSC", text: "THE BANK OF NOVA SCOTIA" },
        { value: "COSB", text: "THE COSMOS CO OPERATIVE BANK LIMITED" },
        { value: "DLSC", text: "THE DELHI STATE COOPERATIVE BANK LIMITED" },
        { value: "GDCB", text: "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED" },
        { value: "GBCB", text: "THE GREATER BOMBAY COOPERATIVE BANK LIMITED" },
        { value: "GSCB", text: "THE GUJARAT STATE COOPERATIVE BANK LIMITED" },
        { value: "HCBL", text: "THE HASTI COOP BANK LTD" },
        { value: "JPCB", text: "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED" },
        { value: "KACE", text: "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED" },
        { value: "KANG", text: "THE KANGRA COOPERATIVE BANK LIMITED" },
        { value: "KUCB", text: "THE KARAD URBAN COOPERATIVE BANK LIMITED" },
        { value: "KSCB", text: "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED" },
        { value: "KSBK", text: "The Kerala State Co Operative Bank Ltd" },
        { value: "KOLH", text: "THE KOLHAPUR URBAN CO-OP. BANK LTD." },
        { value: "KNSB", text: "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED" },
        { value: "TMSB", text: "The Malad Sahakari Bank Ltd" },
        { value: "MKPB", text: "The Malkapur Urban Co Operative Bank Ltd Malkapur" },
        { value: "MSNU", text: "THE MEHSANA URBAN COOPERATIVE BANK" },
        { value: "MDCB", text: "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED" },
        { value: "MUBL", text: "THE MUNICIPAL COOPERATIVE BANK LIMITED" },
        { value: "MSLM", text: "THE MUSLIM CO-OPERATIVE BANK LTD." },
        { value: "NTBL", text: "THE NAINITAL BANK LIMITED" },
        { value: "NMCB", text: "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED" },
        { value: "NVNM", text: "THE NAVNIRMAN CO-OPERATIVE BANK LIMITED" },
        { value: "TNCB", text: "THE NAWANAGAR COOPERATIVE BANK LTD" },
        { value: "NCUB", text: "THE NILAMBUR CO OPERATIVE URBAN BANK LTD NILAMBUR" },
        { value: "PUCB", text: "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR" },
        { value: "TPSC", text: "THE PUNJAB STATE COOPERATIVE BANK LTD" },
        { value: "PUSD", text: "The Pusad Urban Cooperative Bank Ltd Pusad" },
        { value: "RSCB", text: "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED" },
        { value: "ABNA", text: "THE ROYAL BANK OF SCOTLAND N V" },
        { value: "SVBL", text: "THE SEVA VIKAS COOPERATIVE BANK LIMITED" },
        { value: "SVCB", text: "THE SHAMRAO VITHAL COOPERATIVE BANK" },
        { value: "SIDC", text: "THE SINDHUDURG DISTRICT CENTRAL CO-OPERATIVE BANK LIMITED" },
        { value: "SDCB", text: "THE SURAT DISTRICT COOPERATIVE BANK LIMITED" },
        { value: "SPCB", text: "THE SURATH PEOPLES COOPERATIVE BANK LIMITED" },
        { value: "TNSC", text: "THE TAMIL NADU STATE APEX COOPERATIVE BANK" },
        { value: "TBSB", text: "THE THANE BHARAT SAHAKARI BANK LIMITED" },
        { value: "TDCB", text: "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED" },
        { value: "UUCB", text: "THE UDAIPUR URBAN CO OPERATIVE BANK LTD" },
        { value: "URBN", text: "THE URBAN CO-OPERATIVE BANK LTD. NO. ONE SEVEN FIVE EIGHT PERINTHALMANNA" },
        { value: "VARA", text: "THE VARACHHA COOPERATIVE BANK LIMITED" },
        { value: "VCOB", text: "The Vijay Co Operative Bank Limited." },
        { value: "VSBL", text: "THE VISHWESHWAR SAHAKARI BANK LIMITED" },
        { value: "WBSC", text: "THE WEST BENGAL STATE COOPERATIVE BANK" },
        { value: "ZCBL", text: "THE ZOROASTRIAN COOPERATIVE BANK LIMITED" },
        { value: "THRS", text: "THRISSUR DISTRICT CO-OPERATIVE BANK LIMITED" },
        { value: "TJSB", text: "TJSB SAHAKARI BANK LTD" },
        { value: "TGMB", text: "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED" },
        { value: "UCBA", text: "UCO BANK" },
        { value: "UJVN", text: "UJJIVAN SMALL FINANCE BANK LIMITED" },
        { value: "UBIN", text: "UNION BANK OF INDIA" },
        { value: "UTBI", text: "UNITED BANK OF INDIA" },
        { value: "UOVB", text: "UNITED OVERSEAS BANK LIMITED" },
        { value: "UNBA", text: "Unity Small finance Bank Limited" },
        { value: "UCLB", text: "URBAN CO OPERATIVE BANK LTD BAREILLY" },
        { value: "UTKS", text: "UTKARSH SMALL FINANCE BANK" },
        { value: "UPCB", text: "UTTAR PRADESH COOPERATIVE BANK LTD" },
        { value: "VASJ", text: "VASAI JANATA SAHAKARI BANK LTD." },
        { value: "VVSB", text: "VASAI VIKAS SAHAKARI BANK LTD" },
        { value: "VIJB", text: "VIJAYA BANK" },
        { value: "WPAC", text: "WESTPAC BANKING CORPORATION" },
        { value: "HVBK", text: "WOORI BANK" },
        { value: "YESB", text: "YES BANK" },
        { value: "ZSBL", text: "ZILA SAHAKRI BANK LIMITED GHAZIABAD" }
    ],
    cmp_rate_options: [
        { text: '1.00', value: '1.00' },
        { text: '2.00', value: '2.00' },
        { text: '5.00', value: '5.00' },
        { text: '6.00', value: '6.00' }
    ],
    outward_supply_type: [
        { text: 'Goods Manufacturers and Traders', value: '0' },
        { text: 'Restaurants not serving alcohol', value: '1' },
        { text: 'Service providers', value: '2' },
        { text: 'Manufacturers of Ice cream, Pan Masala, Tobaccos', value: '3' }
    ],
    merge_sum: [
        { label: 'Merge', value: '1' },
        { label: 'Sum', value: '0' }
    ],
    gstr2_eligibility_for_itc_option_data: [
        { text: 'Inputs', value: 'Inputs' },
        { text: 'Capital goods', value: 'Capital goods' },
        { text: 'Input services', value: 'Input services' },
        { text: 'Ineligible', value: 'Ineligible' }
    ],
    gstr2_imps_eligibility_for_itc_option_data: [
        { text: 'Input services', value: 'Input services' },
        { text: 'Ineligible', value: 'Ineligible' }
    ],
    gstr2_impg_eligibility_for_itc_option_data: [
        { text: 'Inputs', value: 'Inputs' },
        { text: 'Capital goods', value: 'Capital goods' },
        { text: 'Ineligible', value: 'Ineligible' }
    ],
    c_d_r_option: [
        { text: 'Credit Note', value: 'C' },
        { text: 'Debit Note', value: 'D' },
        { text: 'Refund Voucher', value: 'R' }
    ],
    gstr2_res_for_issuing_doc_option_data: [
        { text: '01-Sales Return', value: '01-Sales Return' },
        { text: '02-Post Sale Discount', value: '02-Post Sale Discount' },
        { text: '03-Deficiency in services', value: '03-Deficiency in services' },
        { text: '04-Correction in Invoice', value: '04-Correction in Invoice' },
        { text: '05-Change in POS', value: '05-Change in POS' },
        { text: '06-Finalization of Provisional assessment', value: '06-Finalization of Provisional assessment' },
        { text: '07-Others', value: '07-Others' }
    ],
    gstr2_supply_type_option_data: [
        { text: 'Inter State', value: 'INTR', label: 'INTR' },
        { text: 'Intra State', value: 'INTRA', label: 'INTRA' }
    ],
    gstr2_document_type_options: [
        { text: 'Imports', value: 'Imports' },
        { text: 'Received from SEZ', value: 'Received from SEZ' }
    ],
    gstr2_invoice_type_options: [
        { text: 'Regular B2B', value: 'R', label: 'R' },
        // { text: 'Export with Payment of GST', value: 'Export with Payment of GST', label: 'WPAY', label1: 'EXPWP' },
        // { text: 'Export without Payment of GST', value: 'Export without Payment of GST', label: 'WOPAY', label1: 'EXPWOP' },
        { text: 'SEZ supplies with payment', value: 'SEWP', label: 'SEWP' },
        { text: 'SEZ supplies without payment', value: 'SEWOP', label: 'SEWOP' },
        { text: 'Deemed Exp', value: 'Deemed Exp', label: 'DE' },
        { text: 'Intra-State supplies attracting IGST', value: 'CBW', label: 'CBW' }
    ],
    gstr2_cdnur_invoice_type_options: [
        { text: 'B2BUR', value: 'B2BUR', label: 'B2BUR' },
        { text: 'IMPS', value: 'IMPS', label: 'IMPS' }
    ],
    match_document_type_options: [
        { label: 'Invoices', value: '0', text: 'Invoices' },
        { label: 'CDN', value: '1', text: 'CDN' },
        { label: 'IMPG SEZ', value: '2', text: 'IMPG SEZ' },
        { label: 'IMPG', value: '3', text: 'IMPG' }
    ],
    match_document_type: [
        { label: 'Invoices', value: '0', text: 'Invoices' },
        { label: 'CDN', value: '1', text: 'CDN' },
        { label: 'IMPG SEZ', value: '2', text: 'IMPG SEZ' }
    ],
    match_summary_type_options: [
        { label: 'Exact Match', value: "0", text: 'Exact Match' },
        { label: 'Suggested Match', value: "1", text: 'Suggested Match' },
        { label: 'Mismatched', value: "2", text: 'Mismatched' },
        { label: 'Missing in 2B', value: "3", text: 'Missing in 2B' },
        { label: 'Missing in PR', value: "4", text: 'Missing in PR' }
    ],
    action_status_options: [
        { text: 'Action', value: 'action' },
        { text: 'No Action', value: 'no_action' }
    ],
    liability_options: [
        { text: 'To be Added', value: 'To be Added' },
        { text: 'Reduced From Output Liability', value: 'Reduced From Output Liability' }
    ],
    itc_claim_options: [
        { text: 'Inputs', value: 'inputs' },
        { text: 'Capital goods', value: 'capital_goods' },
        { text: 'Input services', value: 'input_services' },
        { text: 'Eligible', value: 'eligible' }
    ],
    fieldFilter_options: [
        { label: 'Equals', text: 'Equals', value: '3' },
        { label: 'Less than or equals', text: 'Less than or equals', value: '6' },
        { label: 'Greater than or equals', text: 'Greater than or equals', value: '8' }
    ],
    field_options: [{ label: 'Tax Difference', text: 'Tax Difference', value: 'tax_difference' }],
    condition_option: [
        { text: 'Contains', value: '1' },
        { text: 'Not contains', value: '2' },
        { text: 'Equals', value: '3' },
        { text: 'Not equal', value: '4' },
        { text: 'Less than', value: '5' },
        { text: 'Less than or equals', value: '6' },
        { text: 'Greater than', value: '7' },
        { text: 'Greater than or equals', value: '8' },
        { text: 'Ends with', value: '10' },
        { text: 'In range', value: '11' },
        { text: 'Is empty', value: '12' }
    ],
    invoice_type: {
        'Regular B2B': 'Regular B2B', 'R': 'Regular B2B', 'Export with Payment of GST': 'Export with Payment of GST', 'EXPWP': 'Export with Payment of GST', 'Export without Payment of GST': 'Export without Payment of GST', 'EXPWOP': 'Export without Payment of GST', 'SEZ supplies with payment': 'SEZ supplies with payment', 'SEWP': 'SEZ supplies with payment', 'SEZ supplies without payment': 'SEZ supplies without payment', 'SEWOP': 'SEZ supplies without payment', 'Deemed Exp': 'Deemed Exp', 'DE': 'Deemed Exp', 'Intra-State supplies attracting IGST': 'Intra-State supplies attracting IGST', 'CBW': 'Intra-State supplies attracting IGST', 'INTRA': 'Intra State', 'INTR': 'Inter State'
    },
    nature_of_supply: [
        { text: 'Liable to collect tax u/s 52(TCS)', value: '0' },
        { text: 'Liable to pay tax u/s 9(5)', value: '1' }
    ],
    document_type_options: [
        { text: 'Regular B2B', value: 'R', label: 'Regular' },
        { text: 'SEZ supplies with payment', value: 'SEWP' },
        { text: 'SEZ supplies without payment', value: 'SEWOP' },
        { text: 'Deemed Exp', value: 'DE' }
    ],
    subs_plan_type_options: [
        { text: 'All', value: '' },
        { text: 'Subscription', value: '0' },
        { text: 'User-Addons', value: '1' }
    ],
    subs_status_option: [
        { text: 'All', value: '' },
        { text: 'Success', value: '1' },
        { text: 'In Process', value: '0' },
        { text: 'Failed', value: '2' }
    ],
    pagination_option_10_50: [
        { label: '10', value: '10' },
        { label: '25', value: '25' },
        { label: '50', value: '50' }
    ],
    upload_data_option: [
        { value: '0', text: 'DOCUMENTS' },
        { value: '1', text: 'JSON' },
        { value: '2', text: 'BOTH DOCUMENTS AND JSON' }
    ]
}
export default OptionData